import './videoStyles.scss';

import { memo, useCallback, useEffect, useMemo, useRef } from 'react';

import styles from './TestimonialCard.module.scss';
import type { Testimonial } from '@data/testimonials';
import { VideoJS } from '@components/common/VideoJS';
import type Player from 'video.js/dist/types/player';
import clsx from 'clsx';

export type Props = Testimonial & {
    isActive: boolean;
    index: number;
    playOnMount?: boolean;
    onGoNext?: () => void;
};

const TestimonialCard = ({
    id,
    customerName,
    customerPosition,
    city,
    state,
    logo,
    sources,
    poster,
    isActive,
    index = 0,
    playOnMount = false,
    onGoNext,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const videoPlayerRef = useRef<any>(null);
    const playerRef = useRef<Player>(null);
    const timeoutRef = useRef(0);
    const inViewRef = useRef(false);
    const isFirstRender = useRef(true);

    const onPlayVideo = useCallback(
        ({ detail }: CustomEvent<{ videoId: string; second: string }>) => {
            if (!isActive) return;
            clearTimeout(timeoutRef.current);

            if (!detail || !playerRef.current) return;

            if (detail.videoId !== id) return;

            if (!ref.current?.closest('.slick-active')) return;

            if (videoPlayerRef.current?.isAutoPlaying) {
                videoPlayerRef.current?.setIsAutoPlaying(false);
            }

            playerRef.current?.pause();
            playerRef.current?.currentTime(detail.second);
            playerRef.current?.play();
        },
        [id, playerRef, timeoutRef, inViewRef, isActive],
    );

    const options = useMemo(
        () => ({
            autoplay: index === 0,
            controls: true,
            fluid: true,
            poster: poster.src,
            preload: index === 0 ? 'auto' : 'none',
            sources,
        }),
        [poster, sources, index],
    );

    useEffect(() => {
        isFirstRender.current = false;
    });

    useEffect(() => {
        if (!isActive) {
            if (playerRef.current?.paused()) return;

            playerRef.current?.pause();

            return;
        }

        // if (!ref.current?.closest('.slick-active')) return;
        //
        // if (videoPlayerRef.current?.isAutoPlaying) {
        //     videoPlayerRef.current?.setIsAutoPlaying(false);
        // }
        // playerRef.current?.play();
    }, [isActive]);

    useEffect(() => {
        if (!playOnMount || !playerRef.current) return;

        playerRef.current.muted(false);
        playerRef.current.play();
    }, []);

    useEffect(() => {
        document.addEventListener('play-testimonial-video', onPlayVideo as any);

        return () => {
            document.removeEventListener('play-testimonial-video', onPlayVideo as any);
        };
    }, [onPlayVideo]);

    return (
        <div
            ref={ref}
            className={clsx(
                styles.testimonialCard + ' testimonial-card',
                isActive && 'testimonial-card--active',
            )}>
            <VideoJS
                ref={videoPlayerRef}
                playerRef={playerRef}
                options={options}
                onGoNext={isActive ? onGoNext : undefined}
            />

            <div className={styles.customerInfoHeader}>
                <div className={styles.customerInfoLogo}>
                    <img
                        loading="lazy"
                        decoding="async"
                        src={logo.src}
                        width={logo.width}
                        height={logo.height}
                        alt="logo"
                    />
                </div>
                <strong className={styles.customerCity}>{city}</strong> |
                <span className={styles.customerState}>{state}</span>
            </div>

            <div className={styles.customerInfoFooter}>
                <strong className={styles.customerName}>{customerName}</strong>
                <span className={styles.customerPosition}>{customerPosition}</span>
            </div>
        </div>
    );
};

export default memo(TestimonialCard);
