import OliverAvatar from '@assets/testimonial-customers/avatars/customer-1.jpg';
import BenjaminAvatar from '@assets/testimonial-customers/avatars/customer-2.jpg';
import KlausAvatar from '@assets/testimonial-customers/avatars/customer-3.jpg';
import MarcAvatar from '@assets/testimonial-customers/avatars/customer-4.jpg';
import SimonAvatar from '@assets/testimonial-customers/avatars/customer-5.jpg';
import LauraAvatar from '@assets/testimonial-customers/avatars/customer-6.jpg';
import HolgerAvatar from '@assets/testimonial-customers/avatars/customer-7.jpg';

import Poster1 from '@assets/testimonial-customers/posters/video-1.jpg';
import Poster2 from '@assets/testimonial-customers/posters/video-2.jpg';
import Poster3 from '@assets/testimonial-customers/posters/video-3.jpg';
import Poster4 from '@assets/testimonial-customers/posters/video-4.jpg';
import PosterLaura from '@assets/testimonial-customers/posters/video-6.jpg';
import Poster7 from '@assets/testimonial-customers/posters/video-7.jpg';

import Poster1Small from '@assets/testimonial-customers/posters/video-1-small.jpg';
import Poster2Small from '@assets/testimonial-customers/posters/video-2-small.jpg';
import Poster3Small from '@assets/testimonial-customers/posters/video-3-small.jpg';
import Poster4Small from '@assets/testimonial-customers/posters/video-4-small.jpg';
import Poster7Small from '@assets/testimonial-customers/posters/video-7-small.jpg';

import Baden from '@assets/testimonial-customers/baden.png';
import Bremen from '@assets/testimonial-customers/bremen.png';
import Hamburg from '@assets/testimonial-customers/hamburg.png';
import Bavaria from '@assets/testimonial-customers/muchen.png';

import type { ImageMetadata } from 'astro';
import shuffle from 'lodash.shuffle';
import type { SourceObject } from 'video.js/dist/types/tech/tech';

export type Quote = {
    quote: string;
    customerName: string;
    customerImage: ImageMetadata;
    videoId: string;
    second: number;
};

export const quotes: Quote[] = shuffle([
    {
        quote: 'Unser Büro ist wieder glücklich',
        customerName: 'Simon',
        customerImage: SimonAvatar,
        videoId: 'video-1',
        second: 0,
    },
    {
        quote: 'Ich kann Butlerapp uneingeschränkt empfehlen, 10+',
        customerName: 'Simon',
        customerImage: SimonAvatar,
        videoId: 'video-1',
        second: 0,
    },
    {
        quote: 'Im Prinzip sind wir jetzt arbeitslos',
        customerName: 'Laura',
        customerImage: LauraAvatar,
        videoId: 'video-1',
        second: 0,
    },
    {
        quote: 'Butlerapp war das Beste, was uns passieren konnte.',
        customerName: 'Laura',
        customerImage: LauraAvatar,
        videoId: 'video-1',
        second: 0,
    },
    {
        quote: 'Viel mehr Zeit für die Kunden',
        customerName: 'Laura',
        customerImage: LauraAvatar,
        videoId: 'video-1',
        second: 0,
    },
    {
        quote: 'Von 10 Punkten würde ich 9,5 vergeben',
        customerName: 'Oliver Fratzke',
        customerImage: OliverAvatar,
        videoId: 'video-2',
        second: 0,
    },
    {
        quote: 'Zeitersparnis 80 Prozent',
        customerName: 'Oliver Fratzke',
        customerImage: OliverAvatar,
        videoId: 'video-2',
        second: 0,
    },
    {
        quote: 'Unglaubliches Gefühl der Freiheit',
        customerName: 'Oliver Fratzke',
        customerImage: OliverAvatar,
        videoId: 'video-2',
        second: 0,
    },
    {
        quote: 'Ich gebe eine glatte 10',
        customerName: 'Marc Örding',
        customerImage: MarcAvatar,
        videoId: 'video-3',
        second: 0,
    },
    {
        quote: 'Preis-Leistungs-Verhältnis sehr gut',
        customerName: 'Marc Örding',
        customerImage: MarcAvatar,
        videoId: 'video-3',
        second: 0,
    },
    {
        quote: '80% der Zeit mit dem Butler eingespart',
        customerName: 'Marc Örding',
        customerImage: MarcAvatar,
        videoId: 'video-3',
        second: 0,
    },
    {
        quote: 'klar eine 10 für die Entscheidung',
        customerName: 'Benjamin Möller',
        customerImage: BenjaminAvatar,
        videoId: 'video-2',
        second: 0,
    },
    {
        quote: 'Spart in der Tat Personalkosten',
        customerName: 'Benjamin Möller',
        customerImage: BenjaminAvatar,
        videoId: 'video-4',
        second: 0,
    },
    {
        quote: 'Suche, die schnell und zielführend funktioniert',
        customerName: 'Benjamin Möller',
        customerImage: BenjaminAvatar,
        videoId: 'video-4',
        second: 0,
    },
    {
        quote: 'Ohne Butlerapp benötige ich eine halbe Stelle mehr',
        customerName: 'Klaus Schlösser',
        customerImage: KlausAvatar,
        videoId: 'video-4',
        second: 0,
    },
    {
        quote: 'Viel zuverlässigere Zahlung',
        customerName: 'Klaus Schlösser',
        customerImage: KlausAvatar,
        videoId: 'video-5',
        second: 0,
    },
    {
        quote: 'Automatisch wird der nächste Kurs beworben',
        customerName: 'Klaus Schlösser',
        customerImage: KlausAvatar,
        videoId: 'video-5',
        second: 0,
    },
    {
        quote: 'Sieht so aus, als ob wir 24 Stunden für den Kunden da sind',
        customerName: 'Holger Wetzel',
        customerImage: HolgerAvatar,
        videoId: 'video-6',
        second: 0,
    },
    {
        quote: 'Ich kann ganz offenen Herzens die Butler App mit einer 10 empfehlen',
        customerName: 'Holger Wetzel',
        customerImage: HolgerAvatar,
        videoId: 'video-6',
        second: 0,
    },
    {
        quote: 'Wenn es eine Frage gibt, wird sofort darauf reagiert',
        customerName: 'Holger Wetzel',
        customerImage: HolgerAvatar,
        videoId: 'video-6',
        second: 0,
    },
]);

export type Testimonial = {
    id: string;
    customerName: string;
    customerPosition: string;
    logo: ImageMetadata;
    city: string;
    state: string;
    sources: SourceObject[];
    poster: ImageMetadata;
    posterSmall: ImageMetadata;
};

export const defaultTestimonialsData = {
    sectionTitle: `Automatisierung`,
    title: `<span class="testimonials-section__gradient-text">Unsere Kunden</span><br> sprechen für uns`,
    description: `Was unsere Kunden sagen, spricht davon, was Butlerapp auch für dich tun kann.`,
    title2: `
     What our customers <br />
                    says about
    `,
};

export const testimonials: Testimonial[] = [
    {
        id: 'video-1',
        customerName: 'Laura Ivan',
        customerPosition: 'Inhaberin, Schwimmschule Bavaria',
        city: 'München',
        logo: Bavaria,
        state: 'Bayern',
        sources: [
            // {
            //     type: 'application/dash+xml',
            //     src: '/media/Testimonials/Schwimmschule_Bavaria_x265.dash/stream.mpd',
            //     limitRenditionByPlayerDimensions: true,
            //     useDevicePixelRatio: true,
            // },
            {
                type: 'application/dash+xml',
                src: '/media/Testimonials/Schwimmschule_Bavaria_x264.dash/stream.mpd',
                limitRenditionByPlayerDimensions: true,
                useDevicePixelRatio: true,
            },
            {
                type: 'video/mp4',
                src: '/media/Testimonials/Schwimmschule-Bavaria-compressed.mp4',
            },
        ],
        poster: PosterLaura,
        posterSmall: Poster1Small,
    },
    {
        id: 'video-2',
        customerName: 'Oliver Fratzke',
        customerPosition: 'Gründer & Leiter Heldenweg Institut',
        city: 'Ulm',
        state: 'Baden-Würtenberg',
        logo: Baden,
        sources: [
            // {
            //     type: 'application/dash+xml',
            //     src: '/media/Testimonials/Heldenweg_Institut_x265.dash/stream.mpd',
            //     limitRenditionByPlayerDimensions: true,
            //     useDevicePixelRatio: true,
            // },
            {
                type: 'application/dash+xml',
                src: '/media/Testimonials/Heldenweg_Institut_x264.dash/stream.mpd',
                limitRenditionByPlayerDimensions: true,
                useDevicePixelRatio: true,
            },
            {
                type: 'video/mp4',
                src: '/media/Testimonials/Heldenweg-Institut-compressed.mp4',
            },
        ],
        poster: Poster1,
        posterSmall: Poster1Small,
    },
    {
        id: 'video-3',
        customerName: 'Marc Örding',
        customerPosition: 'Teamleiter Leser GmbH',
        city: 'Hamburg',
        state: 'Hamburg',
        logo: Hamburg,
        sources: [
            // {
            //     type: 'application/dash+xml',
            //     src: '/media/Testimonials/LESER_x265.dash/stream.mpd',
            //     limitRenditionByPlayerDimensions: true,
            //     useDevicePixelRatio: true,
            // },
            {
                type: 'application/dash+xml',
                src: '/media/Testimonials/LESER_x264.dash/stream.mpd',
                limitRenditionByPlayerDimensions: true,
                useDevicePixelRatio: true,
            },
            {
                type: 'video/mp4',
                src: '/media/Testimonials/Leser-compressed.mp4',
            },
        ],
        poster: Poster4,
        posterSmall: Poster4Small,
    },
    {
        id: 'video-4',
        customerName: 'Benjamin Möller',
        customerPosition: 'Geschäftsführer Segelschule Pieper GmbH',
        city: 'Hamburg',
        state: 'Hamburg',
        logo: Hamburg,
        sources: [
            // {
            //     type: 'application/dash+xml',
            //     src: '/media/Testimonials/Pieper_x265.dash/stream.mpd',
            //     limitRenditionByPlayerDimensions: true,
            //     useDevicePixelRatio: true,
            // },
            {
                type: 'application/dash+xml',
                src: '/media/Testimonials/Pieper_x264.dash/stream.mpd',
                limitRenditionByPlayerDimensions: true,
                useDevicePixelRatio: true,
            },
            {
                type: 'video/mp4',
                src: '/media/Testimonials/Pieper-compressed.mp4',
            },
        ],
        poster: Poster2,
        posterSmall: Poster2Small,
    },
    {
        id: 'video-5',
        customerName: 'Klaus Schlösser',
        customerPosition: 'Inhaber von Bootsausbildung.com',
        city: 'Bremen',
        state: 'Bremen',
        logo: Bremen,
        sources: [
            // {
            //     type: 'application/dash+xml',
            //     src: '/media/Testimonials/Bootsausbildung__x265.dash/stream.mpd',
            //     limitRenditionByPlayerDimensions: true,
            //     useDevicePixelRatio: true,
            // },
            {
                type: 'application/dash+xml',
                src: '/media/Testimonials/Bootsausbildung__x264.dash/stream.mpd',
                limitRenditionByPlayerDimensions: true,
                useDevicePixelRatio: true,
            },
            {
                type: 'video/mp4',
                src: '/media/Testimonials/Bootsausbildung-compressed.mp4',
            },
        ],
        poster: Poster3,
        posterSmall: Poster3Small,
    },
    {
        id: 'video-6',
        customerName: 'Holger Wetzel',
        customerPosition: 'Leiter DMYV Prüfungsausschuss Bremen',
        city: 'Bremen',
        state: 'Bremen',
        logo: Bremen,
        sources: [
            // {
            //     type: 'application/dash+xml',
            //     src: '/media/Testimonials/DMYV_x265.dash/stream.mpd',
            //     limitRenditionByPlayerDimensions: true,
            //     useDevicePixelRatio: true,
            // },
            {
                type: 'application/dash+xml',
                src: '/media/Testimonials/DMYV_x264.dash/stream.mpd',
                limitRenditionByPlayerDimensions: true,
                useDevicePixelRatio: true,
            },
            {
                type: 'video/mp4',
                src: '/media/Testimonials/DMYV-compressed.mp4',
            },
        ],
        poster: Poster7,
        posterSmall: Poster7Small,
    },
];

// export const testimonials = [
//     {
//         id: 1,
//         avatar: OliverAvatar.src,
//         avatarFallback: GuntherAvaFallback.src,
//         name: 'Gunther Fürstberger',
//         position: 'Geschäftsführer der Meta GmbH & des MDI Management Development Institutes',
//         quote: '“Mit Butlerapp haben wir unsere Prozesse verschlankt. Jetzt haben wir mehr Klarheit bei den Abläufen und eine deutliche Zeitersparnis bei der Anmeldung der Teilnehmer und bei der Kundenbetreuung. Schätzungsweise 98% aller Buchungen passieren automatisch über die Website und die Rechnungen werden mit der Buchungsbestätigung automatisch verschickt.“',
//         rating: 5,
//         platform: 'google',
//     },
//     {
//         id: 2,
//         avatar: MonikaCors.src,
//         avatarFallback: MonikaCorsFallback.src,
//         name: 'Monika Cors',
//         position: 'Inhaberin der Sprachschule Nativa Akademie',
//         quote: '“Wir sind sehr froh, dass wir uns für Veranstaltungsbutler entschieden haben. Wir haben eine wahnsinnig hohe Zeitersparnis. Die Buchung online klappt hervorragend, die Unterlagen und die Rechnung werden automatisch verschickt, die Daten sind gut organisiert. Die gemeinsame Einrichtung zu Beginn war wunderbar und hat Spaß gemacht, ihr seid immer erreichbar, fantastischer Service - ich kann nur in höchsten Tönen über Veranstaltungsbutler sprechen!“',
//         rating: 5,
//         platform: 'getapp',
//     },
//     {
//         id: 3,
//         avatar: JochenNiesner.src,
//         avatarFallback: JochenNiesnerFallback.src,
//         name: 'Jochen Niesner',
//         position: 'Inhaber der Jagdschule Rhein - Main',
//         quote: '"Wir nutzen seit zwei Jahren die Butlerapp für unsere Kursverwaltung und sind sehr zufrieden. Der Support klappt tadellos persönliche Telefonate mit der Kundenberaterin. Wenn bestimmte Features nicht standardmäßig verfügbsar sind, werden sie personalisiert für uns eingestellt. Butlerapp spart mir mit jeder Kursanmeldung eine Menge Zeit, die ich sonst für die Erfassung der Teilnehmerdaten, Rechnungsstellung und die Benachrichtigung der Teilnehmer investieren müsste."',
//         rating: 5,
//         platform: 'google',
//     },
//     {
//         id: 5,
//         avatar: RalfHeine.src,
//         avatarFallback: RalfHeineFallback.src,
//         name: 'Ralf Heine',
//         position: 'Inhaber Segelschule Godewind',
//         quote: '“Wir benutzen Butlerapp mittlerweile mehrere Jahre. Der Erstkontakt lief noch über die jungen Gründer, die mir mit viel Engagement und Freude eine ihrer ersten Produktversionen vorgestellt haben. Ich habe sie aufs Segelboot geschleppt, damit sie verstehen, was wir unternehmerisch tun. Der Geist von Butlerapp ist immer noch derselbe. Engagiert, innovativ, hilfsbereit, freundlich, schnell, serviceorientiert. Vielen Dank für die Beratung und Betreuung durch euch. Ich bin sehr zufrieden und empfehle euch gern weiter.“',
//         rating: 5,
//         platform: 'google',
//     },
//     {
//         id: 6,
//         avatar: Christopher.src,
//         avatarFallback: ChristopherFallback.src,
//         name: 'Christopher Käßberger',
//         position: 'Inhaber Chiemsee Sailing Center',
//         quote: '“Das Programm ist wirklich super, weil wirklich alles automatisch geht. Ich brauche ja so gut wie nichts machen, selbst wenn der Kunde anruft und ich den Kunden selbst einbuche, geht’s sehr schnell. Der Arbeitsaufwand, den wir uns einsparen ist wirklich enorm. Durch Veranstaltungsbutler haben wir mehr Freiraum und letztlich mehr Lebensqualität. Wir sind auch schneller darin, Kunden zufrieden zustellen. Die Kunden bekommen sofort Rückmeldungen und wir denken, dass auch der Umsatz dadurch steigt, da den Kunden die Buchung sehr leicht gemacht wird und sie nicht auf Rückmeldungen warten und es sich dann in der Wartezeit eventuell anders überlegen.Durch die enge Zusammenarbeit und den Gedankenaustausch mit dem Team von Veranstaltungsbutler haben sich auch viele individuelle Anpassungen ergeben, die mir jetzt bei der Bedienung helfen.“',
//         rating: 5,
//         platform: 'getapp',
//     },
//     {
//         id: 7,
//         avatar: KlausScholesser.src,
//         avatarFallback: KlausScholesserFallback.src,
//         name: 'Klaus Schlösser',
//         position: 'Inhaber von Bootsausbildung.com',
//         quote: '“Was ich super finde ist die einfache Möglichkeit, Kunden in Kursen und Prüfungen zu verwalten und mit ihnen zu kommunizieren und im Kontakt zu stehen. Was uns auch sehr hilft, ist die Einfachheit der automatischen Erstellung von Finanzamt konformen Rechnungen, sowie die automatische Überprüfung des Zahlungseinganges, die den Kunden und Rechnungen direkt zugeordnet wird. Die genannten Punkte führten bei uns zu einer beeindruckenden Erleichterung in der Verwaltung sowie zur Einsparung von Kosten.“',
//         rating: 5,
//         platform: 'google',
//     },
// ];
