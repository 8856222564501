export const chunkArray = (array: any[], numberOfChunks: number = 3) => {
    const chunkedArray = [];
    const chunkSize = Math.ceil(array.length / numberOfChunks);

    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunkedArray.push(chunk);
    }

    return chunkedArray;
};
