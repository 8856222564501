import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slickStyles.scss';

import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { type ResponsiveObject } from 'react-slick';
import { Slider, type SliderRef } from '@components/common/Slider';
import clsx from 'clsx';

import { testimonials, quotes, type Quote } from '@data/testimonials.ts';
import ArrowRight from '@assets/arrow-right-thin.svg?react';
import ArrowLeft from '@assets/arrow-left-thin.svg?react';
import { chunkArray } from '@utils/chunkArray.ts';
import { TestimonialCard } from './TestimonialCard';
import styles from './TestimonialsSectionCarousel.module.scss';
import { InView } from 'react-intersection-observer';

const responsive: ResponsiveObject[] = [
    {
        breakpoint: 768,
        settings: {
            variableWidth: false,
            centerMode: false,
            swipe: true,
        },
    },
];

const chunkedQuotes = chunkArray(quotes, 3);

const QuotesSlider = memo(
    ({ quotes, onClickQuote }: { quotes: Quote[]; onClickQuote: (quote: Quote) => void }) => {
        return (
            <div className={styles.quotesSlider}>
                {new Array(3).fill(' ').map((_, i) => (
                    <div key={i} className={styles.quotesSliderScrollContainer}>
                        {quotes.map(({ customerImage, customerName, quote, videoId, second }) => (
                            <button
                                key={quote}
                                className={styles.quoteItem}
                                onClick={() =>
                                    onClickQuote({
                                        customerImage,
                                        customerName,
                                        quote,
                                        videoId,
                                        second,
                                    })
                                }>
                                <img
                                    className={styles.quoteAvatar}
                                    src={customerImage.src}
                                    width={customerImage.width}
                                    height={customerImage.height}
                                    alt={customerName}
                                    loading="lazy"
                                />

                                <span className={styles.quote}>„{quote}”</span>
                            </button>
                        ))}
                    </div>
                ))}
            </div>
        );
    },
);

const TestimonialsSectionCarousel = () => {
    const carouselRef = useRef<SliderRef>(null);
    const [isSectionVisible, setIsSectionVisible] = useState(false);
    const hasBeenVisible = useRef(false);

    const [currentIndex, setCurrentIndex] = useState(0);

    const onClickLeft = () => {
        carouselRef.current?.slickPrev();
    };

    const onClickRight = () => {
        carouselRef.current?.slickNext();
    };

    const onClickQuote = (quote: Quote) => {
        const testimonialsVideoSection = document.getElementById('testimonial-videos');
        if (!testimonialsVideoSection) return;

        testimonialsVideoSection.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        const videoIndex = testimonials.findIndex(item => item.id === quote.videoId);

        if (videoIndex === undefined) return;
        carouselRef.current?.slickGoTo(videoIndex);

        // setTimeOut is important // this is a workaround for slick slider adding 3 clones for infnite scroll
        setTimeout(() => {
            const event = new CustomEvent<Pick<Quote, 'videoId' | 'second'>>(
                'play-testimonial-video',
                {
                    detail: {
                        videoId: quote.videoId,
                        second: quote.second,
                    },
                },
            );
            document.dispatchEvent(event);
        }, 400);
    };

    const onItemChange = useCallback((itemIndex: number) => {
        setCurrentIndex(itemIndex);
        const video = testimonials[itemIndex];

        setTimeout(() => {
            const event = new CustomEvent<Pick<Quote, 'videoId' | 'second'>>(
                'play-testimonial-video',
                {
                    detail: {
                        videoId: video.id,
                        second: 0,
                    },
                },
            );
            document.dispatchEvent(event);
        }, 400);
    }, []);

    const onSliderVisibilityChange = useCallback((inView: boolean) => {
        if (inView) {
            hasBeenVisible.current = true;
        }
        setIsSectionVisible(inView);
    }, []);

    const onGoNext = useCallback(() => {
        carouselRef.current?.slickNext();
    }, []);

    const preloadIndices = useMemo(
        () => [
            currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1,
            currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1,
        ],
        [currentIndex],
    );

    return (
        <div
            id="testimonial-videos"
            className={
                styles.testimonialsSectionCarouselsContainer +
                ' testimonials-section-image-carousel'
            }>
            <div className={styles.quotesSliderContainer}>
                {chunkedQuotes.map((quoteChunk, i) => (
                    <QuotesSlider key={i} quotes={quoteChunk} onClickQuote={onClickQuote} />
                ))}
            </div>

            <InView
                as="div"
                rootMargin="200px"
                className={styles.testimonialsVideoSliderContainer}
                onChange={onSliderVisibilityChange}>
                <Slider
                    ref={carouselRef}
                    centerMode
                    swipe={false}
                    variableWidth
                    arrows={false}
                    speed={200}
                    infinite
                    cssEase="ease"
                    dots
                    responsive={responsive}
                    afterChange={onItemChange}>
                    {testimonials.map((item, i) => (
                        <div
                            key={item.id}
                            className={styles.testimonialsSectionCarouselItemContainer}>
                            {!isSectionVisible && !hasBeenVisible.current ? null : (
                                <TestimonialCard
                                    {...item}
                                    index={i}
                                    isActive={isSectionVisible && currentIndex === i}
                                    onGoNext={onGoNext}
                                />
                            )}
                        </div>
                    ))}
                </Slider>

                <button
                    className={clsx(
                        styles.testimonialsSectionCarouselButton,
                        styles.testimonialsSectionCarouselButtonLeft,
                    )}
                    aria-label="Go left"
                    onClick={onClickLeft}>
                    <div className={styles.testimonialsSectionCarouselButtonInner}>
                        <ArrowLeft />
                    </div>
                </button>
                <button
                    className={clsx(
                        styles.testimonialsSectionCarouselButton,
                        styles.testimonialsSectionCarouselButtonRight,
                    )}
                    aria-label="Go right"
                    onClick={onClickRight}>
                    <div className={styles.testimonialsSectionCarouselButtonInner}>
                        <ArrowRight />
                    </div>
                </button>

                {/*{!isSectionVisible && !hasBeenVisible.current ? null : (*/}
                {/*    <div className={styles.testimonialsVideoPreloadContainer}>*/}
                {/*        {preloadIndices.map(index => (*/}
                {/*            <video key={index} preload="auto">*/}
                {/*                <source src={testimonials[index].videoSrc} type="video/mp4" />*/}
                {/*            </video>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*)}*/}
            </InView>
        </div>
    );
};

export default memo(TestimonialsSectionCarousel);
